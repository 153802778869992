* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Cairo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[dir="rtl"] .MuiPagination-ul li:nth-of-type(1) svg,
html[dir="rtl"] .MuiPagination-ul li:last-of-type svg {
  transform: scale(-1, 1);
}

a {
  color: inherit;
  text-decoration: none;
}

#html5-qrcode-button-camera-permission {
  border: 1px solid #ddd;
  outline: none;
  background: transparent;
  font-family: "Cairo";
  padding: 1rem;
  cursor: pointer;
}
